$primary: #1a92d0;
$dark: #1a1a1a;

$tablet-size: 767px;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Proxima Nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  color: rgb(54, 54, 56);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes tween-left-to-right {
  from {
    transform: translateX(-20%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes tween-top-to-bottom {
  from {
    transform: translateY(-20%);
  }

  to {
    transform: translateY(0);
  }
}

main {
  position: relative;
  overflow-x: hidden;
  min-height: 100vh;
  padding-bottom: 5rem; // footer compensation
}

section {
  position: relative;
  overflow-x: hidden;

  @media only screen and (min-width: $tablet-size) {
    display: grid;
    grid-gap: 80px;
  }
}

.primary-page {
  padding: 4rem 1rem;
  overflow: hidden;

  @media only screen and (min-width: $tablet-size) {
    min-height: 100vh;
    grid-gap: 4rem;
    padding: 4rem;
    justify-content: space-around;
    grid-template-columns: auto auto;
  }
}

.secondary-page {
  padding: 1rem;

  @media only screen and (min-width: $tablet-size) {
    grid-template-columns: 1fr;
    display: grid;
    grid-gap: 4rem;
    padding: 4rem;
    justify-content: space-around;
    grid-template-columns: auto minmax(auto, 400px);
  }
}

.logo-and-monitor {
  margin-bottom: 4rem;
}

.logo {
  opacity: 0;
  animation: fade-in 1s ease;
  animation-fill-mode: forwards;
  max-width: 360px;
  width: 100%;
  display: block;
  margin-bottom: 40px;
}

.monitor {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
  left: 2vw;
}

.consultation-container {
  z-index: 4;
}

header {
  color: $dark;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    margin-bottom: 0.825rem;

    svg {
      fill: $dark;
      width: 2rem;
      margin-right: 0.75rem;
    }
  }

  .consultation-time {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: $dark;
      width: 1rem;
      margin-right: 0.5rem;
    }
  }

  @media only screen and (min-width: $tablet-size) {
    color: white;
    margin-bottom: 1.25rem;

    .title,
    .consultation-time {
      svg {
        fill: white;
      }
    }
  }
}

.swoosh-top {
  fill: $primary;
  display: block;
  position: absolute;
  top: -1px;
  right: -2px;
  width: 60vw;
  z-index: 3;
}

.swoosh-mid {
  position: absolute;
  bottom: -1px;
  left: 1px;
  z-index: 0;
  fill: $primary;
}

.cta {
  font-family: inherit;
  font-size: 24px;
  border: none;
  outline: none;
  padding: 16px;
  background-color: #1a92d0;
  color: white;
  border-radius: 3px;
}

#calendly-container {
  margin: 0 -1rem;

  @media only screen and (min-width: 640px) {
    margin: 0 4rem;
  }

  @media only screen and (min-width: $tablet-size) {
    margin: 0;
    overflow: hidden;
    border: 1px solid rgba(26, 26, 26, 0.1);
    border-radius: 8px;
    box-shadow: 0 1px 8px 0 rgb(0 0 0 / 8%);
    background-color: white;
  }
}

.bg-primary {
  background-color: $primary;
}

.value-proposition {
  color: white;
  font-size: 1.5em;
  padding: 1em 0 2em;
  line-height: 1.25;

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: 1em;
  }

  li {
    &::before {
      content: "›";
      margin-right: 0.5em;
      position: relative;
      top: -2px;
    }
  }

  @media only screen and (min-width: $tablet-size) {
    display: none;
  }
}

form {
  display: grid;
  background-color: white;
  border: 1px solid rgba(26, 26, 26, 0.1);
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgb(0 0 0 / 8%);
  padding: 1rem;
  grid-gap: 1rem;
  margin-bottom: 2rem;

  input,
  textarea {
    width: 100%;
    display: block;
    margin-top: 8px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  @media only screen and (min-width: 420px) {
    label[for="comment"],
    button {
      grid-column: span 2;
    }
  }
}

button {
  margin-top: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: $primary;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid $primary;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: darken($primary, 10%);
    border-color: darken($primary, 10%);
  }

  svg {
    width: 1rem;
    fill: white;
    margin-right: 0.75rem;
  }
}

figure {
  margin-bottom: 2rem;
}

figcaption::before {
  content: "—";
  margin-right: 0.5em;
}

blockquote {
  margin-bottom: 1rem;
}

footer {
  padding: 1.5rem;
  background-color: $primary;
  text-align: center;
  z-index: 3;
  position: absolute;
  bottom: 0;
  width: 100%;

  .inner {
    display: inline-flex;
    grid-gap: 1.5rem;
  }

  svg {
    fill: white;
    width: 1.75rem;
  }
}

.home {
  .quote-marks {
    margin-bottom: 2rem;

    svg {
      width: 24px;
    }
  }

  figure {
    color: white;
  }

  .quote-marks svg {
    fill: white;
  }
}

.enquiry-submitted,
.booking-confirmed {
  #current-view {
    padding: 5rem 2rem 2rem;
  }

  .headline {
    line-height: 1.2;
    margin-bottom: 1em;

    span {
      display: block;
    }

    @media screen and (min-width: $tablet-size) {
      span {
        display: inline;
      }
    }
  }

  .quote-marks {
    margin-bottom: 1em;

    svg {
      fill: $primary;
      width: 16px;
    }
  }

  .submission-text {
    max-width: 60ch;

    @media screen and (min-width: $tablet-size) {
      margin-left: 3.75rem;
    }
  }
}
